<template>
  <div class="container">
    <div
      v-if="isLoading"
      class="container loader text-center"
    >
      <i class="ti ti-2x ti-spin ti-refresh"></i>
    </div>
    <div
      v-else
      class="row"
    >
      <div class="col-md-6">
        <employees-mosaic
          :employees="employees"
          :has-slider="true"
          @employee-selected="employeeSelected"
        />
      </div>
      <div class="col-md-6 slide-show">
        <div
          v-for="employee in employees"
          :key="employee.id"
          :class="['slide-item', {
            'active': employee.isActive,
          }]"
        >
          <employee-description :employee="employee" />
        </div>
      </div>
    </div>
    <sweet-modal
      ref="modalForm"
      class="modal-form"
      modal-theme="employee-triotech"
    >
      <employee-description
        :employee="activeEmployee"
        :show-employee-modal="showEmployeeModal"
      />
    </sweet-modal>
  </div>
</template>

<script>
  import EmployeesMosaic from './EmployeesMosaic';
  import EmployeeDescription from './EmployeeDescription';

  export default {
    name: 'EmployeesComponent',
    components: {
      EmployeesMosaic,
      EmployeeDescription,
    },
    data() {
      return {
        employees: [],
        isLoading: false,
        animationInterval: null,
        showEmployeeModal: true,
      };
    },
    computed: {
      activeEmployee() {
        return this.employees.find(employee => employee.isActive);
      },
    },
    async mounted() {
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      });
      this.onResize();
      await this.fetchEmployees();
    },
    destroyed() {
      window.removeEventListener('resize', this.onResize);
    },
    methods: {
      async fetchEmployees() {
        this.isLoading = true;
        this.employees = (await this.$ajax.get('public/employee/')).map((employees, idx) => {
          this.$set(employees, 'isActive', 0 === idx);

          return employees;
        });
        this.isLoading = false;
      },
      employeeSelected({ initials }) {
        this.employees.forEach((employee) => {
          employee.isActive = initials === employee.initials;
        });

        if (this.showEmployeeModal) {
          this.openModal()
        } else {
          this.$forceUpdate();
        }
      },
      openModal() {
        this.$refs.modalForm.open(this.activeEmployee);
      },
      onResize() {
        this.showEmployeeModal = 768 > window.innerWidth;
      },
    },
  };
</script>
