<template>
  <div class="container py-3 px-4 px-md-0 py-md-3 my-md-5">
    <h2 v-if="isRelatedContent">
      {{ $t('pages.project.others') }}
    </h2>
    <div v-if="isLoading" class="text-center loader">
      <i class="ti ti-2x ti-spin ti-refresh"></i>
    </div>
    <div v-else class="row">
      <div
        v-for="project in projects"
        :key="project.id"
        class=" col-md-4 mb-4"
      >
        <div class="card card-scale-animation border-0">
          <ResponsivePicture
            :alt="project.thumbnail_image_alt"
            css-class="card-img"
            filter-name="project_thumbnail"
            :src="project.thumbnail_image"
          />
          <div class="card-img-overlay">
            <ResponsivePicture
              v-if="project.client_reference"
              :alt="project.client_reference.label"
              css-class="img-thumbnail"
              filter-name="client_thumbnail_smaller"
              :src="project.client_reference.image"
            />
            <router-link
              class="stretched-link"
              :to="{
                name: 'triotech.front.project.show',
                params: {
                  slug: project.slug,
                },
              }"
            />
          </div>
        </div>
      </div>
    </div>
    <b-pagination-nav
      v-if="!isRelatedContent && 0 < pages"
      align="fill"
      class="mt-4"
      :link-gen="linkGen"
      :number-of-pages="pages"
      size="lg"
      use-router
    />
  </div>
</template>

<script>
  import ResponsivePicture from './ResponsivePicture';

  export default {
    name: 'ProjectsComponent',
    components: {
      ResponsivePicture,
    },
    props: {
      isInline: {
        type: Boolean,
        default: false,
      },
      isSmaller: {
        type: Boolean,
        default: false,
      },
      isRelatedContent: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        mobileApps: [],
        projectCategories: [],
        projects: [],
        pages: 0,
        page: 1,
        isLoading: false,
      };
    },

    beforeMount() {
      this.page = this.$route.query.page ? this.$route.query.page : 1;

      this.fetchProjects();
    },

    methods: {
      linkGen(pageNum) {
        return 1 === pageNum ? '?' : `?page=${pageNum}`
      },
      async fetchProjects() {
        if ((0 !== this.pages && this.page >= this.pages) || this.isLoading) {
          return;
        }

        this.isLoading = true;

        this.$ajax.get(`public/project/?page=${this.page}&limit=${this.isRelatedContent ? '3' : '9'}`)
          .then((data) => {
            if ('pages' in data) {
              this.pages = data.pages;

              this.$set(this, 'projects', this.projects.concat(data._embedded.items));

              this.projects.forEach((project) => {
                this.projectCategories.push(JSON.stringify(project.category));
              });
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    },
  };
</script>
