<template>
  <div class="container py-3 py-md-3 my-md-5">
    <div v-if="isLoading" class="text-center loader">
      <i class="ti ti-2x ti-spin ti-refresh"></i>
    </div>
    <div v-else class="container">
      <slick ref="slick" :options="slickOptions">
        <customer
          v-for="(customer, idx) in carouselCustomers"
          :key="idx"
          :customer="customer"
        />
      </slick>
    </div>
  </div>
</template>

<script>
  import Customer from './Customer';

  export default {
    name: 'CustomersComponent',
    components: {
      Customer,
    },
    data() {
      return {
        customers: [],
        isLoading: false,
        slickOptions: {
          autoplay: true,
          autoplaySpeed: 2000,
          nextArrow: '<button aria-label="Navigation diaporama" type="button" class="slick-next"><i class="ti ti-2x ti-arrow-right"></i></button>',
          prevArrow: '<button aria-label="Navigation diaporama" type="button" class="slick-prev"><i class="ti ti-2x ti-arrow-left"></i></button>',
          slidesToShow: 4,
          swipeToSlide: true,
          responsive: [{
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          }, {
            breakpoint: 725,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          }],
        },
      };
    },
    computed: {
      carouselCustomers() {
        return this.customers.filter(customer => customer.on_carousel);
      },
    },
    beforeMount() {
      this.fetchCustomers();
    },
    methods: {
      next() {
        this.$refs.slick.next();
      },
      prev() {
        this.$refs.slick.prev();
      },
      reInit() {
        this.$nextTick(this.$refs.slick.reSlick);
      },
      async fetchCustomers() {
        this.isLoading = true;
        this.customers = await this.$ajax.get('public/client/');
        this.isLoading = false;
      },
    },
  };
</script>
