import About from '../src/views/About';
import Contact from '../src/views/Contact';
import ContactSuccess from '../src/views/ContactSuccess';
import Expertise from '../src/views/Expertise';
import ExpertiseShow from '../src/views/Expertise/show';
import Home from '../src/views/Home';
import LandingPage from '../src/views/LandingPage';
import LegalNotices from '../src/views/LegalNotices';
import NewsIndex from '../src/views/News/index';
import NewsShow from '../src/views/News/show';
import NotFound from '../src/views/NotFound';
import ProjectIndex from '../src/views/Project/index';
import ProjectShow from '../src/views/Project/show';
import SiteMap from '../src/views/SiteMap';

export default [{
  path: '/',
  name: 'triotech.front.home',
  component: Home,
}, {
  path: '/agence',
  name: 'triotech.front.about',
  component: About,
}, {
  path: '/expertises/',
  name: 'triotech.front.expertise',
  component: Expertise,
}, {
  path: '/realisations/',
  name: 'triotech.front.project.index',
  component: ProjectIndex,
}, {
  path: '/realisations/:slug',
  name: 'triotech.front.project.show',
  component: ProjectShow,
  props: {
    default: true,
  },
}, {
  path: '/actualites/',
  name: 'triotech.front.news.index',
  component: NewsIndex,
}, {
  path: '/actualites/tag/:slug',
  name: 'triotech.front.news.by_tag',
  component: NewsIndex,
}, {
  path: '/actualites/:slug',
  name: 'triotech.front.news.show',
  component: NewsShow,
  props: {
    default: true,
  },
}, {
  path: '/expertises/:slug',
  name: 'triotech.front.expertise.show',
  component: ExpertiseShow,
  props: {
    default: true,
  },
}, {
  path: '/pages/:slug',
  name: 'triotech.front.page.show',
  component: ExpertiseShow,
  props: {
    default: true,
  },
}, {
  path: '/contact',
  name: 'triotech.front.contact',
  component: Contact,
}, {
  path: '/landing-page/web',
  name: 'triotech.front.web',
  component: LandingPage,
}, {
  path: '/landing-page/logiciel',
  name: 'triotech.front.software',
  component: LandingPage,
}, {
  path: '/contact/success',
  name: 'triotech.front.contact_success',
  component: ContactSuccess,
}, {
  path: '/mentions-legales',
  name: 'triotech.front.legal_notices',
  component: LegalNotices,
}, {
  path: '/plan-du-site',
  name: 'triotech.front.site_map',
  component: SiteMap,
}, {
  path: '/404',
  name: 'triotech.front.not_found',
  component: NotFound,
}];
