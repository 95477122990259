<template>
  <div class="container mt-4">
    <div class="row justify-content-center mb-4">
      <div
        v-for="tag in fetchedTags"
        :key="tag.id"
        class="m-1"
      >
        <router-link
          v-if="activeTag === tag.slug"
          class="btn text-uppercase btn-secondary text-white"
          :to="{
            name: 'triotech.front.news.index',
          }"
        >
          {{ tag.label }}
        </router-link>
        <router-link
          v-else
          class="btn text-uppercase btn-light border-dark"
          :to="{
            name: 'triotech.front.news.by_tag',
            params: {
              slug: tag.slug,
            },
          }"
        >
          {{ tag.label }}
        </router-link>
      </div>
    </div>
    <div class="row">
      <div
        v-for="item in displayedNews"
        :key="item.id"
        class="col-md-6 col-lg-4 mb-4"
      >
        <NewsCard
          :has-three="hasThree"
          :item="item"
        />
      </div>
    </div>
    <b-pagination-nav
      v-if="!hasThree && 0 < pages"
      align="fill"
      class="mt-4"
      :link-gen="linkGen"
      :number-of-pages="pages"
      size="lg"
      use-router
    />
    <div v-if="isLoading" class="text-center loader">
      <i class="ti ti-2x ti-spin ti-refresh"></i>
    </div>
  </div>
</template>

<script>
  import NewsCard from './NewsCard';

  export default {
    name: 'NewsCards',
    components: {
      NewsCard,
    },
    props: {
      hasThree: {
        type: Boolean,
        default: false,
      },
      isHomePage: {
        type: Boolean,
        default: false,
      },
      news: {
        type: Array,
        default: () => [],
      },
      hasRelatedNews: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        activeTag: null,
        fetchedNews: [],
        fetchedTags: [],
        page: 1,
        pages: 0,
        isLoadingNews: false,
        isLoadingTags: false,
      };
    },
    computed: {
      displayedNews() {
        if (0 < this.news.length) {
          return this.news;
        }

        return this.fetchedNews;
      },
      isLoading() {
        return this.isLoadingNews || this.isLoadingTags;
      },
    },
    beforeMount() {
      this.page = this.$route.query.page ? this.$route.query.page : 1;
      this.activeTag = this.$route.params.slug ?? null;

      this.fetchNews();
      this.fetchTags();
    },
    mounted() {
      this.$bus.$on('news.fetch', this.fetchNews);
    },
    methods: {
      linkGen(pageNum) {
        return 1 === pageNum ? '?' : `?page=${pageNum}`;
      },
      async fetchTags() {
        if (0 < this.fetchedTags.length || this.isLoadingTags) {
          return;
        }

        this.isLoadingTags = true;

        this.$ajax.get('public/tag/')
          .then((data) => {
            this.fetchedTags = data;
          })
          .finally(() => {
            this.isLoadingTags = false;
          });
      },
      async fetchNews() {
        if (0 < this.news.length) {
          return;
        }

        if ((0 !== this.pages && this.page >= this.pages) || this.isLoadingNews) {
          return;
        }

        this.isLoadingNews = true;

        const params = new URLSearchParams('embed=1');

        params.append('limit', this.hasThree ? '3' : '12');
        params.append('page', this.page.toString(10));

        if (null !== this.activeTag) {
          params.append('criteria[tag][value]', this.activeTag);
        }

        this.$ajax.get(`public/news/?${params.toString()}`)
          .then((data) => {
            if ('pages' in data) {
              this.pages = data.pages;

              this.$set(this, 'fetchedNews', this.fetchedNews.concat(data._embedded.items));
            }
          })
          .finally(() => {
            this.isLoadingNews = false;
          });
      },
    },
  };
</script>
