<template>
  <footer
    :class="[
      'footer-menu', 'bg-extra-light-grey', 'mb-n3', 'pt-0', {
        'absolute-footer': isMenuFooter,
      }]"
  >
    <div class="container px-4 px-md-0 py-3">
      <div class="bottom-top-containers">
        <img
          alt="Logo Triotech"
          class="footer-logo ml-n2"
          height="80"
          src="/static/images/brand/logo-couleur.png"
          width="300"
        />
      </div>
      <div class="row py-3">
        <div class="col-6 col-lg-4 mb-4">
          <b class="font-weight-bold text-secondary footer-title text-uppercase h5">
            {{ $t('menu.contact') }}
          </b>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <address class="text-dark mt-3" v-html="$t('pages.contact.address.text')"></address>
          <div class="p-0">
            <a
              v-for="item in detailsAction"
              :key="item.id"
              class="text-dark nav-link p-0"
              :href="item.link"
              @click="trackClick($event, item)"
            >
              <span @click="closeMenu">{{ item.title }}</span>
            </a>
          </div>
        </div>
        <div
          v-for="item in menu"
          :key="item.id"
          :class="item.class"
        >
          <b class="font-weight-bold text-secondary footer-title text-uppercase h5">
            {{ item.title }}
          </b>
          <ul class="list-unstyled footer-line-height mt-3">
            <li
              v-for="subitem in item.submenu"
              :key="subitem.id"
            >
              <router-link
                class="nav-item text-dark"
                :to="subitem.link"
              >
                {{ subitem.title }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-6 col-lg-1">
          <div class="d-flex justify-content-lg-center card-scale-animation">
            <router-link
              to="./actualites/pourquoi-faire-appel-au-credit-dimpot-innovation-cii-pour-vos-projets-numeriques-sur-mesure"
            >
              <img
                alt="Logo CII impôts"
                class="cii-logo"
                src="../../static/images/icons/logo-cii.png"
              />
            </router-link>
          </div>
        </div>
      </div>
      <div class="row justify-content-between align-items-center mb-5 mb-md-0">
        <div class="col-6 col-lg-4">
          <p class="footer-copyright text-dark text-left">
            {{ $t('footer.copyright') }}
          </p>
        </div>
        <div class="col-6 col-lg-1">
          <social-networks class="float-right" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  import SocialNetworks from './SocialNetworks';

  export default {
    name: 'FooterMenuComponent',
    components: {
      SocialNetworks,
    },
    props: {
      isHomePage: {
        type: Boolean,
        default: false,
      },
      isMenuFooter: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        detailsAction: [
          {
            title: this.$t('pages.contact.coordinate.phone'),
            isExternal: true,
            link: `tel:${this.$t('pages.contact.coordinate.call_phone')}`,
            trackEvent: {
              category: 'CTA',
              action: 'footer_phone',
            },
          }, {
            title: this.$t('pages.contact.coordinate.mail'),
            isExternal: true,
            link: `mailto:${this.$t('pages.contact.coordinate.mail')}`,
            trackEvent: {
              category: 'CTA',
              action: 'footer_email',
            },
          }],
        menu: {
          skills: {
            title: this.$t('menu.expertise'),
            class: 'col-md-6 col-lg-4',
            link: {
              name: 'triotech.front.expertise',
            },
            submenu: [],
          },
          about: {
            title: this.$t('menu.about'),
            class: 'col-md-6 col-lg-3',
            link: {
              name: 'triotech.front.about',
            },
            submenu: [{
              title: this.$t('menu.agency'),
              link: {
                name: 'triotech.front.about',
              },
            }, {
              title: this.$t('footer.blog'),
              link: {
                name: 'triotech.front.news.index',
              },
            }, {
              title: this.$t('footer.legal_notices'),
              link: {
                name: 'triotech.front.legal_notices',
              },
            }, {
              title: this.$t('menu.get_in_touch'),
              link: {
                name: 'triotech.front.contact',
              },
            }],
          },
        },
      }
    },
    beforeMount() {
      this.fetchSkills();

      ['corporate_social_responsibility', 'privacy_policy', 'recruitment'].forEach((landingPage) => {
        this.addLandingPageToMenu(landingPage, this.menu.about.submenu);
      });
    },
    methods: {
      addLandingPageToMenu(landingPageName, submenu) {
        if (null !== this.$config.get(`page_slug.${landingPageName}`)) {
          submenu.push({
            title: this.$t(`footer.${landingPageName}`),
            link: {
              name: 'triotech.front.page.show',
              params: { slug: this.$config.get(`page_slug.${landingPageName}`) },
            },
          });
        }
      },
      openModal(modal) {
        this.$refs[modal].open();
      },
      closeMenu() {
        if (this.isMenuFooter) {
          this.$bus.$emit('animateMenu');
        }
      },
      trackClick(event, item) {
        if (item.trackEvent) {
          this.$gtm.trackEvent(item.trackEvent);

          if (item.isExternal) {
            event.preventDefault();
            event.stopPropagation();

            setTimeout(() => {
              window.location = item.link;
            }, 100);
          }
        }
      },
      fetchSkills() {
        this.$ajax.get('public/expertise/')
          .then((data) => {
            const processedData = data.map(datum => ({
              title: datum.label,
              link: {
                name: 'triotech.front.expertise.show',
                params: {
                  slug: datum.slug,
                },
              },
            }));

            this.$set(this.menu.skills, 'submenu', processedData);
          });
      },
    },
  };
</script>
