<template>
  <div>
    <navigation
      :index="{ name: 'triotech.front.expertise', hash: '#skills' }"
      :index-text="$t('actions.show_expertise_list')"
      :links="expertise._links"
      :next-text="$t('actions.show_expertise_next')"
      param="slug"
      :prev-text="$t('actions.show_expertise_prev')"
      route="triotech.front.expertise.show"
    />
    <div class="card">
      <div class="card-img">
        <ResponsiveBanners
          :alt="expertise.image_title"
          css-class="img-fluid"
          :desktop-banner="expertise.image"
          filter-name="dynamic_page_banner"
          :mobile-banner="expertise.small_image"
          :src="expertise.image"
        />
      </div>
    </div>
    <div class="container py-3 px-4 px-md-0 py-md-3 my-md-5">
      <h1 class="text-center">
        {{ expertise.label }}
      </h1>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="expertise.content"></div>
      <div
        v-if="expertise.visible"
        class="text-center my-4"
      >
        <router-link
          class="btn btn-dark rounded-pill text-light"
          :to="{ name: 'triotech.front.expertise', hash: '#skills' }"
        >
          {{ $t('actions.show_expertise_list') }}
        </router-link>
      </div>
    </div>
    <ask-block />
  </div>
</template>

<script>
  import Navigation from '../../components/Navigation';
  import AskBlock from '../../components/AskBlock';
  import ResponsiveBanners from '../../components/ResponsiveBanners';

  export default {
    name: 'ExpertiseShowPage',
    components: {
      AskBlock,
      Navigation,
      ResponsiveBanners,
    },
    metaInfo() {
      if (null !== this.expertise) {
        return {
          title: this.expertise.meta_title,
          meta: [
            { property: 'og:url', content: this.$config.get('basepath') + window.location.pathname.replace(/\/$/, '') },
            { property: 'og:title', content: this.expertise.meta_title },
            { property: 'og:description', content: this.expertise.meta_description },
            { name: 'description', content: this.expertise.meta_description },
          ],
          link: [
            { rel: 'canonical', href: this.$config.get('basepath') + window.location.pathname.replace(/\/$/, '') },
          ],
        };
      }
      return {};
    },
    data() {
      return {
        expertise: {},
      };
    },
    async mounted() {
      this.expertise = await this.$ajax
        .get(`public/expertise/${this.$route.params.slug}`)
        .catch(() => {
          this.$router.push({ name: 'triotech.front.not_found' });
        });

      if (this.expertise.visible && 'triotech.front.page.show' === this.$route.name) {
        this.$router.push({
          name: 'triotech.front.expertise.show',
          params: { slug: this.expertise.slug },
        });
      } else if (!this.expertise.visible && 'triotech.front.expertise.show' === this.$route.name) {
        this.$router.push({
          name: 'triotech.front.page.show',
          params: { slug: this.expertise.slug },
        });
      }
    },
  };
</script>
